.PostPage {
  display: block;
}

.PostPage textarea {
  resize: none;
  width: 100%;
  font-size: 18px;
  background-color: inherit;
  caret-color: white;
  border: none;
  color: white;
  outline: none;
  box-sizing: border-box;
}

.PostPage :disabled {
  filter: brightness(65%);
}

.PostPage-post-button button {
  margin: 0 0 0 auto;
  display: block;
}
