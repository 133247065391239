.UserProfile {
  padding: 5px;
}

.UserProfile-top {
  display: flex;
  border-bottom: rgb(56, 68, 77) solid 1px;
}

.UserProfile-top > div {
  padding: 5px;
}

.UserProfile-photo img {
  width: 65px;
}

.UserProfile-name {
  font-size: 18px;
}

.UserProfile-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.UserProfile-delete-button button {
  background-color: #E1235E;
}

.UserProfile-posted-contents {
  overflow-y: scroll;
}
