.Post {
  padding: 9px 14px;
  border-bottom: rgb(56, 68, 77) solid 1px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: stretch;
  font-size: 14px;
}

.Post-user-icon {
  box-sizing: border-box;
  width: 37px;
  min-width: 37px;
  text-size-adjust: 100%;
  margin-right: 10px;
}

.Post-user-icon img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

.Post-username-timestamp-content {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-size-adjust: 100%;
  flex-grow: 1;
  min-width: 0;
}

.Post-top-line {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Post-top-line-left-side {
  align-items: baseline;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  min-width: 0;
}

.Post-top-line-right-side {
  align-items: stretch;
  box-sizing: border-box;
  display: flex;
  width: 14px;
  height: 20px;
  min-width: 14px;
  max-width: 14px;
  margin-left: 18px;
}

.Post-username-wrapper {
  display: flex;
  overflow: hidden;
}

.Post-username {
  font-weight: bold;
  color: white;
  text-decoration: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Post-username:hover {
  text-decoration: underline;
}

.Post-timestamp-separator {
  color: #8896A6;
  margin: 0px 5px;
}

.Post-timestamp {
  color: #8896A6;
  min-width: 130px;
}

.Post-delete-icon {
  max-width: 100%;
  max-height: 100%;
}

.Post-delete-icon img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.Post-content {
  box-sizing: border-box;
  display: block;
  overflow-wrap: break-word;
  position: relative;
  white-space: pre-wrap;
}
