.SignIn {
  padding: 10px;
  text-align: center;
}

.SignIn-top-message {
  font-size: 28px;
}

.SignIn span {
  display: inline-block;
}

.SignIn-description {
  padding-top: 30px;
}

.SignIn-button {
  margin-top: 30px;
  text-align: center;
}

.SignIn-button button {
  font-size: 18px;
  border-radius: 40px;
}
