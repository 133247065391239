.App {
  text-align: left;
  display: block;
  color: white;
  font-family: Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, メイリオ, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.App button {
  font-weight: bold;
  font-size: 14px;
  color: white;
  border: none;
  border-radius: 15px;
  background-color: #1da1f2;
  padding: 3px 14px;
}

.App-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: rgb(56, 68, 77) solid 1px;
  padding: 7px;
  z-index: 999;
  background-color: #16202C;
}

.App-header > div {
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
}

.App-header img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

.App-main {
  position: relative;
  display: block;
  overflow-y: scroll;
}

.App-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}

.App-link:hover {
  text-decoration: underline;
}
